import {defaultEnvironment, Environment} from './default-environment';

export const environment: Environment = {
  ...defaultEnvironment,
  name: 'uat',
  sickIdUrl: 'https://id.uat.sick.com/',
  oidc: {
    ...defaultEnvironment.oidc,
    issuer: 'https://id.uat.sick.com/auth/realms/sickservices'
  },
  url: {
    aws: `https://wmx9cbswtb.execute-api.eu-central-1.amazonaws.com/uat/app`,
    assets: `https://assets.flowsic-applicationdesign.uat-cloud.sick.com/`,
    flowTwin: 'https://flowsic-applicationdesign.uat-cloud.sick.com/'
  },
  initializeSentry: true
};
